function t_close(url) {
  if (document.referrer.includes(window.location.host)) {
    window.history.back();
  } else {
    window.location.href = url;
  }
}
/* $('#dot0').hide(); */
$("#dot1").hide();
$("#dot2").hide();
$("#dot3").hide();
$("#dot4").hide();
$("#dot5").hide();

$(".dot").click(function () {
  var index = $(this).index();
  //get active dot index
  var activeIndex = $(".dot.active").index();

  $(".dot").eq(activeIndex).removeClass("active");
  $(".dot").eq(index).addClass("active");
  $("#dot" + activeIndex).hide();
  $("#dot" + index).show();
});

$(".question-icon").click(function () {
  var index = $(this).closest(".questions").find(".question-icon").index(this);
  /* control if has active class */
  if ($(".question-answer").eq(index).hasClass("active")) {
    $(".question-answer").eq(index).removeClass("active");
  } else {
    $(".question-answer").eq(index).addClass("active");
  }

  /* $(".question-answer").eq(index).addClass("active"); */
});
